<template>
  <div>
    <div class="installment" v-if="key1 == '11'">
      <div class="installment_title">
        <img src="@/assets/image/PersonalCenter/MyAccount/contentItemTitle.png" alt="">
        分期购计算器
      </div>
      <div class="installment_inputG">
        <div id="amount">
          金额：
          <el-input v-model="amount" placeholder="请输入" :maxlength="6" @input="amountChange"></el-input>
        </div>
        <div id="downPay">
          首付：
          <el-select v-model="downPay" :popper-append-to-body="false" placeholder="请选择" @change="downPayChange">
            <el-option
              v-for="(item,index) in downPaySelect"
              :key="index"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div id="staging">
          期数：
          <el-select v-model="staging" :popper-append-to-body="false" placeholder="请选择" @change="stagingChange">
            <el-option
              v-for="(item,index) in stagingSelect"
              :key="index"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="installment_content">
        <div class="installment_content_left contentItem">
          <div>首付款：{{ downPayMoney }}</div>
          <div>总还款：{{ totalMoney }}</div>
        </div>
        <div class="installment_content_right contentItem">
          <div class="monthlyPayment_title">月付款：</div>
          <div class="monthlyPayment">
            <div v-for="(item,index) in monthlyPayment" :key="index">{{ item }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="ql-container ql-snow">
      <p class="aboutContent ql-editor" v-html="pageInfo"></p>
    </div>
  </div>

</template>
<script>
import {apiPageInfo} from "@/request/API";

export default {
  name: '',
  props: {
    key1: String,
  },
  components: {

  },
  data () {
    return {
      pageInfo:'',
      // 分期购 金额
      amount:'',
      // 分期购 首付下拉框
      downPaySelect: [
        {value: 3, label: '首付三成'},
        {value: 4, label: '首付四成'},
        {value: 5, label: '首付五成'},
        {value: 6, label: '首付六成'},
      ],
      // 分期购 首付
      downPay: 3,
      // 分期购 期数下拉框
      stagingSelect:[
        {value: 1, label: '一期'},
        {value: 2, label: '二期'},
        {value: 3, label: '三期'},
      ],
      // 分期购 期数
      staging: 1,
      // 分期购 首付款
      downPayMoney:'',
      // 分期购 首付款
      totalMoney:'',
      // 分期购 月付款
      monthlyPayment:[],
      // 分期购 尾款
      finalMoney:0,
      // 分期购 本息
      principalAndInterest:0
    }
  },
  methods: {
    getPageInfo(){
      apiPageInfo({
        id:this.key1
      }).then(res=>{
        this.pageInfo = res.data.content
      })
    },


    amountChange(){
      this.monthlyPaymentCom()
      if (!this.amount) {
        this.downPayMoney = ''
        this.totalMoney = ''
        this.monthlyPayment = []
      }
    },
    downPayChange(){
      this.amountChange()
    },
    stagingChange(){
      this.amountChange()
    },
    monthlyPaymentCom(){
      this.downPayMoney = parseFloat(this.amount * (this.downPay / 10)).toFixed(0)
      this.finalMoney = this.amount - this.downPayMoney
      this.monthlyPayment = []
      this.principalAndInterest = 0
      this.totalMoney = 0
      //每月月供
      let total = 0
      //每月本金
      let money = 0
      //每月利息
      let interest = 0
      //每月本金 = 尾款 / 期数
      money = parseFloat((this.finalMoney)/this.staging).toFixed(0)
      for (let i = 0; i < this.staging; i++) {
        // 利息 = 尾款 * 0.1
        if (this.finalMoney < 10000) {
          interest = parseFloat(this.finalMoney * 0.1).toFixed(0)
        }else {
          interest = parseFloat(this.finalMoney * 0.08).toFixed(0)
        }
        //剩余尾款 = 尾款 - 每月本金
        this.finalMoney = this.finalMoney*1 - money
        //每月月供 = 本金 + 利息
        total = money*1 + interest*1
        this.monthlyPayment.push(i+1 + '期: ￥'+total+' （ 本金￥'+ money +' + 折损费￥'+interest+' ）')
        //本利 = 每月月供总和
        this.principalAndInterest = this.principalAndInterest + total
      }
      //总还款 = 本息 + 首付款
      this.totalMoney = this.principalAndInterest*1 + this.downPayMoney*1
    }
  },
  mounted () {
    this.getPageInfo()
  },
  watch: {
    key1(val, oldVal){//普通的watch监听
      this.key1 = val
      this.getPageInfo()
    },
  },
  computed: {

  }
}
</script>

<style  scoped>
.ql-container{
  border: none !important;
}
.aboutContent{
  padding: 0;
  /*width: 871px;*/
  overflow: auto;
  margin: 0;
}
.aboutContent >>> img{
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

</style>
<style lang="less" scoped>
  .installment{
    padding: 50px 40px 0 40px ;
    //background: lightpink;
    .installment_title{
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #FF9407;
      line-height: 28px;
      display: flex;
      align-items: center;
      flex-direction: row;
      img{
        margin:  0 20px 0 0;
      }
    }
    .installment_inputG{
      margin: 30px 0 10px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      div{
        flex: 1;
        font-size: 18px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;
        color: #8F8F8F;
        line-height: 25px;
        /deep/.el-input__inner:focus{
          border-color: #FCAD13 !important;
        }
        /deep/.el-select-dropdown__item.hover{
          background-color:#FFFBF3;
        }
        /deep/.el-select-dropdown__item.selected{
          color:#FCAD13;
        }
        /deep/.el-input.is-focus .el-input__inner{
          border-color:#FCAD13 !important;
        }
      }
      #amount{
        display: flex;
        flex-direction: row;
        align-items: center;
        /deep/.el-input__inner{
          width: 193px !important;
          font-size: 14px;
          //color: #C0C4CC;
          line-height: 14px;
        }

      }
      #downPay{
      }
      #staging{
      }
    }
    .installment_content{
      width: 796px;
      height: 72px;
      border-radius: 20px;
      border: 1px solid #DCDFE6;
      display: flex;
      flex-direction: row;
      padding: 20px 35px;
      .contentItem{
        font-size: 18px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;
        color: #8F8F8F;
        line-height: 25px;
      }
      .installment_content_left{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 35%;
        div{
          width: 100%;
          height: 25px;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
      }
      .installment_content_right{
        width: 65%;
        display: flex;
        flex-direction: row;
        .monthlyPayment_title{
          width: 72px;
        }
        .monthlyPayment{
          width: calc(~"100% -  72px");
          div{
            width: 100%;
            height: 25px;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
          }

        }
      }
    }
  }
</style>
