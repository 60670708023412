<template>
  <div class="recruit">
    <div class="header">
      <div class="left">
        <img src="@/assets/image/About/Recruit/headerIcon.png" alt="">
        招贤纳士
      </div>
      <!--      <div class="right">
              <div class="btn downloadBtn" @click="downLoad">下载简历模板</div>
              <div class="btn submitBtn" @click="submitDialog = true">投递简历</div>
            </div>-->

    </div>
    <div class="body">
      <div class="list">
        <div class="item" v-for="(item,index) in recruitList" :key="index" @click="recruitItemClick(item)">
          <div class="info">
            <div class="info_top">
              <div class="title">{{ item.title }}</div>
              <div class="money">{{ item.wage }}</div>
            </div>
            <div class="info_bottom">
              {{ item.city }}&ensp;｜&ensp;{{ item.experience }}&ensp;｜&ensp;{{ item.education }}
            </div>
          </div>
          <div class="address">
            <div class="text">{{ item.address }}</div>
            <div class="checkBtn">查看详情 <i class="el-icon-caret-right"></i></div>
          </div>
        </div>
      </div>
      <div class="tip">
        <img src="@/assets/image/About/Recruit/tip.png" alt="">
      </div>
    </div>

    <!-- 投递建立 -->
    <el-dialog
      :visible.sync="submitDialog"
      custom-class="Unpublished"
      :show-close="false"
      width="358px"
      top="35vh"
      center>
          <span>
            <div id="UPS_main">
              <div class="UPS_info">
                <div class="UPS_name">投递方式</div>
                <div class="UPS_text">请将简历投递{{ recruitInfo.email }}</div>
              </div>
              <div class="UPS_btn">
                <button class="UPS_continue ESconfirm" @click.once="copyWechat(recruitInfo.email)" target="_blank">点击复制</button>
              </div>
            </div>
            <img class="UPS_close" src="@/assets/image/AccountDeal/close.png" alt="" @click="submitDialog = false">
          </span>
    </el-dialog>

    <!-- 招聘详情 -->
    <el-dialog
      :visible.sync="recruitDialog"
      custom-class="recruitInfo"
      :show-close="false"
      width="676px"
      top="6vh"
      center>
          <span>
            <div id="recruit_Info">
              <div class="RI_header">
                招聘详情
                <img class="RI_close" @click="recruitInfoClose" src="@/assets/image/AccountDeal/tipClose.png"
                     alt="">
              </div>
              <div class="RI_body">
                <div class="baseInfo">
                  <div class="info_head">
                    <div class="name">{{ recruitInfo.title }}</div>
                    <div class="money">{{ recruitInfo.wage }}</div>
                  </div>
                  <div class="info_main">
                    <div class="infoOther">
                      <div>
                        <img src="@/assets/image/About/Recruit/infoIcon1.png" alt="">{{
                          recruitInfo.experience
                        }}
                      </div>
                      <div>
                        <img src="@/assets/image/About/Recruit/infoIcon2.png" alt="">{{
                          recruitInfo.education
                        }}
                      </div>
                      <div>
                        <img src="@/assets/image/About/Recruit/infoIcon3.png"
                             alt="">上班时间：{{ recruitInfo.worktime }}
                      </div>
                    </div>
                    <div class="infoAddress">
                      <img src="@/assets/image/About/Recruit/infoIcon4.png" alt="">
                      <span>{{ recruitInfo.address }}</span>
                    </div>
                  </div>
                </div>
                <div class="jobDetails">
                  <div class="JD_header">
                    <span></span>
                    职位详情
                  </div>
                  <div class="JD_main ql-container ql-snow">
                    <p class=" ql-editor" v-html="recruitInfo.description"></p>
                  </div>
                </div>
              </div>
              <div class="RI_footer">
                <div class="RI_btn downloadBtn" @click.once="downLoad(recruitInfo.file_url)">下载简历模板</div>
                <div class="RI_btn submitBtn" @click="submitDialog = true">投递简历</div>
              </div>
            </div>
          </span>
    </el-dialog>
  </div>
</template>

<script>
import {apiRecruitList, apiRecruitInfo} from "@/request/API";

export default {
  name: 'recruit',
  props: {},

  data() {
    return {
      submitDialog: false,
      recruitDialog: false,

      //招聘列表
      recruitList: [],
      //招聘详情
      recruitInfo: {}
    }
  },
  methods: {
    /**
     * 复制微信号
     * @param text 要复制的文本
     */
    copyWechat(text) {
      var text = text;
      var input = document.getElementById("input");
      input.value = text; // 修改文本框的内容
      input.select(); // 选中文本
      document.execCommand('copy'); // 执行浏览器复制命令
      this.$message({
        type: "success",
        center: true,
        message: '复制成功'
      })
    },
    /**
     * 下载应聘人员登记表.doc文件
     * @param href 下载地址
     */
    downLoad(href) {
      let link = document.createElement('a')
      link.href = href
      link.target = '_black'
      link.setAttribute('download', '应聘人员登记表') //指定下载后的文件名，防跳转
      link.click()
    },

    /**
     * 招聘项点击
     * @param item
     */
    recruitItemClick(item) {
      this.recruitDialog = true
      this.getRecruitInfo(item.id)
    },

    /**
     * 获取招聘列表
     */
    getRecruitList() {
      apiRecruitList({})
        .then(res => {
          this.recruitList = res.data
        })
    },
    /**
     * 获取招聘详情
     * @param item
     */
    getRecruitInfo(id) {
      apiRecruitInfo({
        id: id
      }).then(res => {
        this.recruitInfo = res.data
      })
    },

    /**
     * 招聘详情弹窗关闭
     */
    recruitInfoClose(){
      this.recruitDialog = false
      this.recruitInfo = {}
    }
  },
  mounted() {
    this.getRecruitList()
  },
  watch: {},
  computed: {}
}
</script>


<style  scoped>
.ql-container{
  border: none !important;
}
.ql-editor{
  padding: 0;
  margin: 0;
}
.JD_main{
  padding: 0;
  overflow: auto;
  margin: 0;
}
.JD_main >>> img{
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

</style>
<!-- 弹框 -->
<style lang="less">
.Unpublished {
  width: 358px;
  background: #FFFFFF;
  border-radius: 10px;
  position: relative;
  overflow: visible;

  .el-dialog__body {
    padding: 33px 0px 35px;
    position: relative;

    #UPS_main {
      display: flex;
      flex-direction: column;
      align-items: center;

      .UPS_info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0 0 19px;

        .UPS_name {
          align-self: center;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #353535;
          line-height: 20px;
          margin: 0 0 20px;
        }

        .UPS_text {
          font-size: 18px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: CENTER;
          color: #353535;
        }
      }

      .UPS_btn {
        display: flex;
        justify-content: space-between;

        > button {
          display: inline-block;
          width: 139px;
          height: 38px;
          border-radius: 25px;
          font-size: 16px;
          color: #000000;
          text-align: center;
          line-height: 38px;
          cursor: pointer;
        }

        .UPS_reset {
          border: 1px #000000 solid;
          background-color: #fff;
          margin-right: 14px;
        }

        .UPS_continue {
          border: 1px #434ED6 solid;
          background-color: #434ED6;
          color:#FFFFFF;
        }

        .ESconfirm {
          width: 233px;
        }
      }
    }

    .UPS_close {
      width: 30px;
      position: absolute;
      bottom: -40px;
      left: 159px;
      cursor: pointer;
    }
  }

  .el-dialog__header {
    padding: 0;
  }
}

.recruitInfo {
  height: 750px;
  border-radius: 10px;

  .el-dialog__body {
    padding: 0 !important;

    #recruit_Info {
      border-radius: 10px;

      .RI_header {
        position: relative;
        height: 70px;
        line-height: 70px;
        text-align: center;
        background: linear-gradient(135deg, #646AB5 0%, #404797 100%);
        font-size: 22px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #ffffff;
        border-radius: 10px 10px 0 0;

        .RI_close {
          width: 20px;
          height: 20px;
          position: absolute;
          right: 30px;
          top: 24px;
          cursor: pointer;
        }
      }

      .RI_body {
        height: 600px;
        overflow: auto;

        .baseInfo {
          padding: 27px 30px 21px;

          .info_head {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .name {
              font-size: 22px;
              font-family: PingFang SC, PingFang SC-Medium;
              font-weight: 500;
              color: #343a42;
            }

            .money {
              font-size: 24px;
              font-family: PingFang SC, PingFang SC-Semibold;
              font-weight: 600;
              color: #fcad13;
            }
          }

          .info_main {
            .infoOther {
              margin: 15px 0;
              display: flex;
              align-items: center;

              div {
                display: flex;
                align-items: center;
                font-size: 16px;
                font-family: PingFang SC, PingFang SC-Regular;
                font-weight: 400;
                color: #585b60;
                margin: 0 50px 0 0;

                img {
                  width: 18px;
                  height: 18px;
                  margin: 0 4px 0 0;
                }
              }
            }

            .infoAddress {
              display: flex;
              align-items: center;
              font-size: 16px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              color: #585b60;
              margin: 0 50px 0 0;

              div {
              }

              img {
                width: 18px;
                height: 18px;
                margin: 0 4px 0 0;
              }
            }
          }
        }

        .jobDetails {
          .JD_header {
            display: flex;
            align-items: center;
            height: 40px;
            background: #f6f6f6;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            color: #343a42;

            span {
              margin: 0 15px 0 30px;
              width: 7px;
              height: 18px;
              background: #fcad13;
              border-radius: 10px;
              display: inline-block;
            }
          }

          .JD_main {
            padding: 19px 33px;
          }
        }
      }

      .RI_footer {
        height: 80px;
        background: #ffffff;
        box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.10);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 0 10px 10px;

        .RI_btn {
          width: 186px;
          height: 48px;
          line-height: 48px;
          text-align: center;
          border: 1px solid #000000;
          border-radius: 8px;
          font-size: 20px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #000000;
          margin: 0 10px;
          cursor: pointer;
        }

        .submitBtn {
          border: 1px solid #434ED6;
          background: #434ED6;
          color: #FFFFFF;
        }
      }
    }
  }

  .el-dialog__header {
    padding: 0;
  }
}
</style>

<style scoped lang="less">
.recruit {
  height: 100%;
  background: linear-gradient(180deg, #f0f0f0, #FFFFFF 3%);

  .header {
    height: 80px;
    border-radius: 10px 10px 0 0;
    background: linear-gradient(135deg, #646AB5 0%, #404797 100%);
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      font-size: 20px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: RIGHT;
      color: #ffffff;
    }

    .left {
      display: flex;
      align-items: center;

      img {
        margin: 0 9px 0 0;
      }

      span {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #ffffff;
      }
    }

    .right {
      display: flex;
      align-items: center;

      .btn {
        width: 124px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        border: 1px solid #ffffff;
        border-radius: 8px;
        margin: 0 0 0 10px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
      }

      .submitBtn {
        background: #ffffff;
        color: #303f4f;
      }
    }
  }

  .body {
    padding: 27px 30px 0;

    .list {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;

      .item {
        width: 400px;
        height: 100px;
        background: #f6f6f8;
        border-radius: 10px;
        margin: 0 0 10px;
        cursor: pointer;
        padding: 14px 20px;

        .info {
          padding: 0 0 14.5px;
          border-bottom: 1px solid #e2e2ed;
          margin: 0 0 12.5px;

          .info_top {
            padding: 0 10px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
              font-size: 16px;
              font-family: PingFang SC, PingFang SC-Medium;
              font-weight: 500;
              color: #585b60;
            }

            .money {
              font-size: 20px;
              font-family: PingFang SC, PingFang SC-Medium;
              font-weight: 500;
              color: #fcad13;
            }
          }

          .info_bottom {
            margin: 4px 0 0;
            padding: 0 10px 0;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #585b60;
          }
        }

        .address {
          padding: 0 0 0 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .text {
            width: 264px;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #585b60;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .checkBtn {
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            color: #585b60;
          }

        }
      }

      .item:hover {
        background: #fff7e9;
      }
    }

    .tip {
      position: relative;

      .wechat {
        cursor: pointer;
      }

      .wechatTip {
        position: absolute;
        left: 210px;
        top: 64px;
      }
    }
  }

  #input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -10;
  }
}

</style>
